import CRUDService from '@/services/hrsg/CRUDService';

export class ReviewService extends CRUDService {
  constructor () {
    super('reviews.reviews');
  }

  async completeSetup (id, data) {
    const response = await this.request({
      endpoint: `${this.endpoint}.complete-setup`,
      pathParams: this.getPathvars({ id }),
      data
    });

    return response?.payload?.data ?? [];
  }

  async verifyAssessments (id, data) {
    const response = await this.request({
      endpoint: `${this.endpoint}.verify-assessments`,
      pathParams: this.getPathvars({ id }),
      data
    });

    return response?.payload?.data ?? [];
  }

  async close (id) {
    return await this.request({
      endpoint: `${this.endpoint}.close`,
      pathParams: this.getPathvars({ id })
    });
  }

  async reopen (id) {
    return await this.request({
      endpoint: `${this.endpoint}.reopen`,
      pathParams: this.getPathvars({ id })
    });
  }

  async duplicate (id) {
    return await this.request({
      endpoint: `${this.endpoint}.duplicate`,
      pathParams: this.getPathvars({ id })
    });
  }

  async summary (id, queryParams = {}) {
    const response = await this.request({
      endpoint: `${this.endpoint}.summary`,
      pathParams: this.getPathvars({ id }),
      queryParams
    });

    return response?.payload?.data ?? [];
  }

  async shareResults (id) {
    const response = await this.request({
      endpoint: `${this.endpoint}.share-results`,
      pathParams: this.getPathvars({ id })
    });

    return response?.payload?.data ?? [];
  }

  async results (id, type, queryParams = {}) {
    const response = await this.request({
      endpoint: `${this.endpoint}.results`,
      pathParams: this.getPathvars({ id, type }),
      queryParams
    });

    return response?.payload?.data ?? [];
  }

  async checkAdmin (id) {
    const response = await this.request({
      endpoint: `${this.endpoint}.check-admin`,
      pathParams: this.getPathvars({ id })
    });

    return response?.payload?.data ?? [];
  }
}

export default new ReviewService();
