import middleware from './middleware';
// Constants
import permissions from '@/constants/permissions';
import features from '@/constants/features';
import libraries from '@/constants/libraries';
import modules from '@/constants/modules';

export default [
  {
    path: '/login',
    name: 'login',
    meta: {
      documentTitle: ['quinto_login'],
      middleware: [middleware.unauth]
    },
    component: () => import('@/views/Login')
  },
  {
    path: '/sso-login',
    name: 'sso-login',
    meta: {
      documentTitle: ['quinto_login'],
      middleware: [middleware.unauth]
    },
    component: () => import('@/views/SsoLogin')
  },
  {
    path: '/create-password',
    name: 'create-password',
    meta: {
      middleware: [middleware.unauth]
    },
    component: () => import('@/views/CreatePassword')
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    meta: {
      middleware: [middleware.unauth]
    },
    component: () => import('@/views/ResetPassword')
  },
  {
    path: '/',
    name: 'home-index',
    meta: {
      documentTitle: ['quinto_home'],
      breadcrumbs: ['home'],
      middleware: [middleware.auth]
    },
    component: () => import('@/components/VRouterView'),
    redirect: { name: 'home' },
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('@/views/Home')
      },
      {
        path: '/profile',
        name: 'profile-and-settings',
        meta: {
          middleware: [middleware.auth]
        },
        component: () => import('@/views/profile/Profile'),
        redirect: { name: 'profile-settings' },
        children: [
          {
            path: 'settings',
            name: 'profile-settings',
            meta: {
              documentTitle: ['profile_settings', 2],
              middleware: [middleware.hasPermission(permissions.MANAGE_MY_ACCOUNT)]
            },
            component: () => import('@/views/profile/Settings')
          },
          {
            path: 'notifications',
            name: 'profile-email-notifications',
            meta: {
              documentTitle: ['profile_email_notifications', 2],
              middleware: [middleware.hasPermission(permissions.MANAGE_MY_ACCOUNT)]
            },
            component: () => import('@/views/profile/EmailNotifications')
          },
          {
            path: 'description',
            name: 'profile-job-description',
            meta: {
              documentTitle: ['profile_job_description']
            },
            component: () => import('@/views/profile/JobDescription')
          },
          {
            path: 'signoffs',
            name: 'profile-signoffs',
            meta: {
              documentTitle: ['profile_job_signoffs', 2]
            },
            component: () => import('@/views/profile/Signoffs')
          }
        ]
      }
    ]
  },
  {
    path: '/tasks',
    name: 'tasks',
    meta: {
      middleware: [
        middleware.auth
      ]
    },
    component: () => import('@/components/VRouterView'),
    redirect: { name: 'tasks-index' },
    children: [
      {
        path: '',
        name: 'tasks-index',
        meta: {
          documentTitle: ['tasks', 2]
        },
        props: true,
        component: () => import('@/views/profile/Tasks')
      },
      {
        path: 'surveys/:id',
        name: 'tasks-surveys-id',
        props: true,
        meta: {
          middleware: [middleware.hasModule(modules.SURVEYS)]
        },
        component: () => import('@/views/surveys/Survey')
      },
      {
        path: 'signoffs/:id',
        name: 'tasks-signoffs-id',
        props: true,
        meta: {
          middleware: [middleware.hasFeature(features.COMPLIANCE_SIGNOFFS)]
        },
        component: () => import('@/views/content/jobs/Signoff')
      }
    ]
  },
  {
    path: '/my-team',
    name: 'my-team',
    meta: {
      documentTitle: ['my_team'],
      middleware: [
        middleware.auth,
        middleware.hasPermission(permissions.MANAGE_PEOPLE)
      ]
    },
    component: () => import('@/components/VRouterView'),
    redirect: { name: 'my-team-index' },
    children: [
      {
        path: '',
        name: 'my-team-index',
        meta: {
          documentTitle: ['my_team']
        },
        component: () => import('@/views/myTeams/Index')
      },
      {
        path: ':id',
        name: 'my-team-id',
        props: true,
        meta: {
          documentTitle: ['my_team']
        },
        component: () => import('@/views/myTeams/Team')
      }
    ]
  },
  {
    path: '/analytics',
    name: 'analytics',
    meta: {
      documentTitle: ['analytics', 2],
      middleware: [
        middleware.auth,
        middleware.hasPermission(permissions.ACCESS_ANALYTICS),
        middleware.hasFeature(features.ANALYTICS)
      ]
    },
    component: () => import('@/views/Analytics')
  },
  {
    path: '/organization',
    name: 'organization',
    meta: {
      middleware: [middleware.auth]
    },
    component: () => import('@/components/VRouterView'),
    redirect: { name: 'organization-org-chart' },
    children: [
      {
        path: 'org-chart',
        name: 'organization-org-chart',
        meta: {
          documentTitle: ['org_chart'],
          middleware: [middleware.hasFeature(features.ORG_CHART)]
        },
        component: () => import('@/views/organization/orgChart/Index')
      },
      {
        path: 'org-chart/:id',
        name: 'organization-org-chart-id',
        props: true,
        meta: {
          documentTitle: ['org_chart']
        },
        component: () => import('@/views/organization/orgChart/Index')
      }
    ]
  },
  {
    path: '/content',
    name: 'content',
    meta: {
      middleware: [middleware.auth]
    },
    component: () => import('@/components/VRouterView'),
    redirect: { name: 'content-jobs' },
    children: [
      {
        path: 'jobs',
        name: 'content-jobs',
        meta: {
          breadcrumbs: ['jobs', 2],
          permissions: [permissions.ACCESS_JOBS]
        },
        component: () => import('@/components/VRouterView'),
        redirect: { name: 'content-jobs-index' },
        children: [
          {
            path: '',
            name: 'content-jobs-index',
            meta: {
              documentTitle: ['jobs', 2],
              middleware: [
                middleware.hasPermission(permissions.ACCESS_JOBS),
                middleware.hasModule(modules.JOBS)
              ]
            },
            component: () => import('@/views/content/jobs/Index')
          },
          {
            path: 'signoffs',
            name: 'content-jobs-signoffs',
            meta: {
              documentTitle: ['signoffs', 2],
              middleware: [
                middleware.hasPermission(permissions.MANAGE_SIGNOFFS),
                middleware.hasFeature(features.COMPLIANCE_SIGNOFFS)
              ]
            },
            component: () => import('@/views/content/jobs/Signoffs')
          },
          {
            path: 'level-analysis',
            name: 'content-jobs-level-analysis',
            component: () => import('@/components/VRouterView'),
            redirect: { name: 'content-jobs-level-analysis-competencies' },
            children: [
              {
                path: 'competencies',
                name: 'content-jobs-level-analysis-competencies',
                meta: {
                  documentTitle: ['level_analysis_competencies', 2],
                  middleware: [
                    middleware.hasFeature(features.ANALYTICS),
                    middleware.hasPermission(permissions.ACCESS_ANALYTICS)
                  ]
                },
                component: () => import('@/views/content/jobs/levelAnalysis/Competencies')
              },
              {
                path: 'skills',
                name: 'content-jobs-level-analysis-skills',
                meta: {
                  documentTitle: ['level_analysis_skills', 2],
                  middleware: [
                    middleware.hasFeature(features.ANALYTICS),
                    middleware.hasPermission(permissions.ACCESS_ANALYTICS)
                  ]
                },
                component: () => import('@/views/content/jobs/levelAnalysis/Skills')
              }
            ]
          },
          {
            path: 'archive',
            name: 'content-jobs-archive',
            meta: {
              documentTitle: ['jobs_archive', 2],
              middleware: [middleware.hasPermission(permissions.ACCESS_JOBS)]
            },
            component: () => import('@/views/content/jobs/Archive')
          },
          {
            path: ':id',
            name: 'content-jobs-id',
            props: true,
            meta: {
              permissions: [permissions.ACCESS_JOBS],
              middleware: [middleware.hasPermission(permissions.ACCESS_JOBS, permissions.JOBS_COLLABORATION)]
            },
            component: () => import('@/views/content/jobs/JobView'),
            redirect: { name: 'content-jobs-id-description' },
            children: [
              {
                path: 'description',
                name: 'content-jobs-id-description',
                meta: {
                  middleware: [middleware.hasPermission(permissions.ACCESS_JOBS, permissions.JOBS_COLLABORATION, permissions.MANAGE_JOBS)]
                },
                component: () => import('@/views/content/jobs/JobDescription')
              },
              {
                path: 'post',
                name: 'content-jobs-id-post',
                meta: {
                  middleware: [middleware.hasPermission(permissions.MANAGE_JOBS, permissions.OWN_JOBS)]
                },
                component: () => import('@/views/content/jobs/JobPost')
              },
              {
                path: 'guides',
                name: 'content-jobs-id-guides',
                meta: {
                  middleware: [
                    middleware.hasPermission(permissions.ACCESS_JOBS),
                    middleware.hasPermission(permissions.ACCESS_INTERVIEW_GUIDE_TEMPLATES)
                  ]
                },
                component: () => import('@/views/content/jobs/JobGuides')
              },
              {
                path: 'employees',
                name: 'content-jobs-id-employees',
                meta: {
                  middleware: [middleware.hasPermission(permissions.MANAGE_JOBS, permissions.OWN_JOBS)]
                },
                component: () => import('@/views/content/jobs/JobEmployees')
              },
              {
                path: 'history',
                name: 'content-jobs-id-history',
                meta: {
                  middleware: [middleware.hasPermission(permissions.MANAGE_JOBS, permissions.OWN_JOBS)]
                },
                component: () => import('@/views/content/jobs/JobHistory')
              },
              {
                path: 'signoffs',
                name: 'content-jobs-id-signoffs',
                meta: {
                  middleware: [
                    middleware.hasPermission(permissions.ACCESS_JOBS),
                    middleware.hasPermission(permissions.MANAGE_SIGNOFFS)
                  ]
                },
                component: () => import('@/views/content/jobs/JobSignoffs')
              }
            ]
          },
          {
            path: ':id/edit',
            name: 'content-jobs-id-edit',
            props: true,
            meta: {
              permissions: [permissions.ACCESS_JOBS],
              middleware: [middleware.hasPermission(permissions.ACCESS_JOBS, permissions.JOBS_COLLABORATION)]
            },
            component: () => import('@/views/content/jobs/JobEdit')
          },
          {
            path: 'history',
            name: 'content-jobs-history',
            component: () => import('@/components/VRouterView'),
            redirect: { name: 'content-jobs-history-id' },
            children: [
              {
                path: ':id',
                name: 'content-jobs-history-id',
                props: true,
                meta: {
                  middleware: [middleware.hasPermission(permissions.ACCESS_JOBS)]
                },
                component: () => import('@/views/content/jobs/JobFullHistoryView')
              }
            ]
          },
          {
            path: 'groups',
            name: 'content-job-groups',
            meta: {
              breadcrumbs: ['groups', 2],
              middleware: [middleware.hasPermission(permissions.MANAGE_JOBS)]
            },
            component: () => import('@/components/VRouterView'),
            redirect: { name: 'content-job-groups-index' },
            children: [
              {
                path: '',
                name: 'content-job-groups-index',
                meta: {
                  documentTitle: ['job_groups', 2]
                },
                component: () => import('@/views/content/jobs/Groups')
              },
              {
                path: ':id',
                name: 'content-job-groups-id',
                props: true,
                component: () => import('@/views/content/jobs/Group')
              }
            ]
          }
        ]
      },
      {
        path: 'reviews',
        name: 'content-reviews',
        meta: {
          breadcrumbs: ['reviews', 2]
        },
        component: () => import('@/components/VRouterView'),
        redirect: { name: 'content-reviews-index' },
        children: [
          {
            path: '',
            name: 'content-reviews-index',
            meta: {
              documentTitle: ['reviews', 2]
            },
            component: () => import('@/views/reviews/Index')
          },
          {
            path: ':id',
            name: 'content-reviews-id',
            props: true,
            component: () => import('@/views/reviews/Review'),
            redirect: { name: 'content-reviews-index' },
            children: [
              {
                path: 'setup',
                name: 'content-reviews-id-setup',
                meta: {
                  breadcrumbs: ['setup']
                },
                component: () => import('@/views/reviews/setup/Index'),
                redirect: { name: 'content-reviews-id-setup-details' },
                children: [
                  {
                    path: 'details',
                    name: 'content-reviews-id-setup-details',
                    component: () => import('@/views/reviews/setup/Details')
                  },
                  {
                    path: 'viewpoints',
                    name: 'content-reviews-id-setup-viewpoints',
                    component: () => import('@/views/reviews/setup/Viewpoints')
                  },
                  {
                    path: 'questions',
                    name: 'content-reviews-id-setup-questions',
                    component: () => import('@/views/reviews/setup/Questions')
                  },
                  {
                    path: 'participants',
                    name: 'content-reviews-id-setup-participants',
                    component: () => import('@/views/reviews/setup/Participants')
                  },
                  {
                    path: 'schedule',
                    name: 'content-reviews-id-setup-schedule',
                    component: () => import('@/views/reviews/setup/Schedule')
                  },
                  {
                    path: 'review',
                    name: 'content-reviews-id-setup-review',
                    component: () => import('@/views/reviews/setup/Review')
                  },
                  {
                    path: 'communications',
                    props: true,
                    name: 'content-reviews-id-setup-communications',
                    component: () => import('@/views/reviews/setup/Communications')
                  }
                ]
              },
              {
                path: 'assessments',
                name: 'content-reviews-id-assessments',
                props: true,
                component: () => import('@/components/VRouterView'),
                children: [
                  {
                    path: '',
                    name: 'content-reviews-id-assessments-index',
                    component: () => import('@/views/reviews/Assessments')
                  },
                  {
                    path: ':assessmentId',
                    name: 'content-reviews-id-assessments-id',
                    component: () => import('@/components/VRouterView'),
                    props: true,
                    meta: {
                      documentTitle: ['assessments', 2]
                    },
                    children: [
                      {
                        path: 'participants/:participantId',
                        name: 'content-reviews-id-assessments-id-participant-id',
                        meta: { documentTitle: ['questionnaires', 2] },
                        component: () => import('@/components/VRouterView'),
                        props: true,
                        children: [
                          {
                            path: 'questionnaire',
                            name: 'content-reviews-id-assessments-id-participant-id-questionnaire-index',
                            component: () => import('@/views/reviews/actualReview/Index'),
                            redirect: { name: 'content-reviews-id-assessments-id-participant-id-questionnaire-summary' },
                            children: [
                              {
                                path: 'summary',
                                name: 'content-reviews-id-assessments-id-participant-id-questionnaire-summary',
                                component: () => import('@/views/reviews/actualReview/Summary')
                              },
                              {
                                path: 'question/:questionId',
                                name: 'content-reviews-id-assessments-id-participant-id-questionnaire-question',
                                component: () => import('@/views/reviews/actualReview/Question')
                              },
                              {
                                path: 'review',
                                name: 'content-reviews-id-assessments-id-participant-id-questionnaire-review',
                                component: () => import('@/views/reviews/actualReview/Review')
                              }
                            ]
                          },
                          {
                            path: 'view-questionnaire',
                            name: 'content-reviews-id-assessments-id-participant-id-view-questionnaire-index',
                            component: () => import('@/views/reviews/actualReview/ReadOnlyQuestionnaire'),
                            redirect: { name: 'content-reviews-id-assessments-id-participant-id-view-questionnaire-summary' },
                            children: [
                              {
                                path: 'summary',
                                name: 'content-reviews-id-assessments-id-participant-id-view-questionnaire-summary',
                                component: () => import('@/views/reviews/actualReview/Summary')
                              },
                              {
                                path: 'question/:questionId',
                                name: 'content-reviews-id-assessments-id-participant-id-view-questionnaire-question',
                                component: () => import('@/views/reviews/actualReview/Question')
                              },
                              {
                                path: 'review',
                                name: 'content-reviews-id-assessments-id-participant-id-view-questionnaire-review',
                                component: () => import('@/views/reviews/actualReview/Review')
                              }
                            ]
                          }
                        ]
                      },
                      {
                        path: 'results',
                        name: 'content-reviews-id-assessments-id-results',
                        meta: { documentTitle: ['questionnaire_results'] },
                        component: () => import('@/views/reviews/actualReview/results/Index')
                      }
                    ]
                  }
                ]
              },
              {
                path: 'participants',
                name: 'content-reviews-id-participants',
                component: () => import('@/views/reviews/Participants')
              },
              {
                path: 'results',
                name: 'content-reviews-id-results',
                component: () => import('@/views/reviews/Results')
              }
            ]
          }
        ]
      },
      {
        path: 'libraries',
        name: 'content-libraries',
        meta: {
          breadcrumbs: ['libraries', 2]
        },
        component: () => import('@/views/content/libraries/Index'),
        children: [
          {
            path: 'competency-questions',
            name: 'content-interviews-competency-questions',
            meta: {
              documentTitle: ['library_competency_questions', 2],
              breadcrumbs: ['competency_questions', 2],
              middleware: [
                middleware.hasLibrary(libraries.INTERVIEW_QUESTIONS),
                middleware.hasPermission(permissions.MANAGE_INTERVIEW_QUESTIONS, permissions.CONTENT_ADMINISTRATOR, permissions.MANAGE_INTERVIEWS)
              ]
            },
            component: () => import('@/components/VRouterView'),
            redirect: { name: 'content-interviews-competency-questions-index' },
            children: [
              {
                path: '',
                name: 'content-interviews-competency-questions-index',
                component: () => import('@/views/content/interviews/competencyQuestions/Index')
              },
              {
                path: ':id',
                name: 'content-interviews-competency-questions-id',
                props: true,
                meta: {
                  documentTitle: ['library_competency_questions']
                },
                component: () => import('@/views/content/interviews/competencyQuestions/CompetencyQuestion')
              }
            ]
          },
          {
            path: 'topic-questions',
            name: 'content-interviews-topic-questions',
            meta: {
              documentTitle: ['library_topic_questions', 2],
              breadcrumbs: ['topic_questions', 2],
              middleware: [
                middleware.hasLibrary(libraries.INTERVIEW_QUESTIONS),
                middleware.hasPermission(permissions.MANAGE_INTERVIEW_QUESTIONS, permissions.CONTENT_ADMINISTRATOR, permissions.MANAGE_INTERVIEWS)
              ]
            },
            component: () => import('@/components/VRouterView'),
            redirect: { name: 'content-interviews-topic-questions-index' },
            children: [
              {
                path: '',
                name: 'content-interviews-topic-questions-index',
                component: () => import('@/views/content/interviews/topicQuestions/Index')
              },
              {
                path: ':id',
                name: 'content-interviews-topic-questions-id',
                props: true,
                meta: {
                  documentTitle: ['library_topic_questions']
                },
                component: () => import('@/views/content/interviews/topicQuestions/TopicQuestion')
              },
              {
                path: 'topics',
                name: 'content-interviews-topics',
                component: () => import('@/views/content/interviews/topics/Index')
              }
            ]
          },
          {
            path: 'competencies',
            name: 'content-libraries-competencies',
            meta: {
              breadcrumbs: ['competencies', 2],
              middleware: [
                middleware.hasLibrary(libraries.COMPETENCIES),
                middleware.hasPermission(permissions.ACCESS_LIBRARIES)
              ]
            },
            component: () => import('@/components/VRouterView'),
            redirect: { name: 'content-libraries-competencies-index' },
            children: [
              {
                path: '',
                name: 'content-libraries-competencies-index',
                meta: {
                  documentTitle: ['libraries_competencies', 2]
                },
                component: () => import('@/views/content/libraries/competencies/Index')
              },
              {
                path: 'groups',
                name: 'content-libraries-competencies-groups',
                meta: {
                  breadcrumbs: ['groups', 2],
                  documentTitle: ['library_group_competencies', 2],
                  middleware: [middleware.hasLibrary(libraries.COMPETENCIES)]
                },
                component: () => import('@/views/content/libraries/competencies/Groups')
              },
              {
                path: 'archive',
                name: 'content-libraries-competencies-archive',
                meta: {
                  documentTitle: ['library_archive_competencies', 2],
                  middleware: [middleware.hasLibrary(libraries.COMPETENCIES)]
                },
                component: () => import('@/views/content/libraries/competencies/Archive')
              },
              {
                path: ':id',
                name: 'content-libraries-competencies-id-view',
                props: (route) => ({
                  id: route.params.id,
                  editing: false
                }),
                meta: {
                  middleware: [middleware.hasLibrary(libraries.COMPETENCIES)]
                },
                component: () => import('@/views/content/libraries/competencies/Competency')
              },
              {
                path: ':id',
                name: 'content-libraries-competencies-id-edit',
                props: (route) => ({
                  id: route.params.id,
                  editing: true
                }),
                meta: {
                  middleware: [middleware.hasLibrary(libraries.COMPETENCIES)]
                },
                component: () => import('@/views/content/libraries/competencies/Competency')
              }
            ]
          },
          {
            path: 'responsibilities',
            name: 'content-libraries-responsibilities',
            meta: {
              breadcrumbs: ['responsibilities', 2],
              middleware: [
                middleware.hasLibrary(libraries.RESPONSIBILITIES),
                middleware.hasPermission(permissions.ACCESS_LIBRARIES)
              ]
            },
            component: () => import('@/components/VRouterView'),
            redirect: { name: 'content-libraries-responsibilities-index' },
            children: [
              {
                path: '',
                name: 'content-libraries-responsibilities-index',
                meta: {
                  documentTitle: ['libraries_responsibilities', 2]
                },
                component: () => import('@/views/content/libraries/responsibilities/Index')
              },
              {
                path: 'groups',
                name: 'content-libraries-responsibility-groups',
                meta: {
                  breadcrumbs: ['groups', 2],
                  documentTitle: ['library_group_responsibilities', 2]
                },
                component: () => import('@/views/content/libraries/responsibilities/Groups')
              },
              {
                path: 'archive',
                name: 'content-libraries-responsibility-archive',
                meta: {
                  documentTitle: ['library_archive_responsibilities', 2]
                },
                component: () => import('@/views/content/libraries/responsibilities/Archive')
              }
            ]
          },
          {
            path: 'skills',
            name: 'content-libraries-skills',
            meta: {
              breadcrumbs: ['skills', 2],
              middleware: [
                middleware.hasLibrary(libraries.SKILLS),
                middleware.hasPermission(permissions.ACCESS_LIBRARIES)
              ]
            },
            component: () => import('@/components/VRouterView'),
            redirect: { name: 'content-libraries-skills-index' },
            children: [
              {
                path: '',
                name: 'content-libraries-skills-index',
                meta: {
                  documentTitle: ['libraries_skills', 2]
                },
                component: () => import('@/views/content/libraries/skills/Index')
              },
              {
                path: 'groups',
                name: 'content-libraries-skill-groups',
                meta: {
                  breadcrumbs: ['groups', 2],
                  documentTitle: ['library_group_skills', 2]
                },
                component: () => import('@/views/content/libraries/skills/Groups')
              },
              {
                path: 'archive',
                name: 'content-libraries-skills-archive',
                meta: {
                  documentTitle: ['library_archive_skills', 2]
                },
                component: () => import('@/views/content/libraries/skills/Archive')
              }
            ]
          },
          {
            path: 'education',
            name: 'content-libraries-education',
            meta: {
              breadcrumbs: ['educations', 2],
              middleware: [
                middleware.hasLibrary(libraries.EDUCATIONS),
                middleware.hasPermission(permissions.ACCESS_LIBRARIES)
              ]
            },
            component: () => import('@/components/VRouterView'),
            redirect: { name: 'content-libraries-education-index' },
            children: [
              {
                path: '',
                name: 'content-libraries-education-index',
                meta: {
                  documentTitle: ['libraries_educations', 2]
                },
                component: () => import('@/views/content/libraries/education/Index')
              },
              {
                path: 'groups',
                name: 'content-libraries-education-groups',
                meta: {
                  breadcrumbs: ['groups', 2],
                  documentTitle: ['library_group_educations', 2]
                },
                component: () => import('@/views/content/libraries/education/Groups')
              },
              {
                path: 'archive',
                name: 'content-libraries-education-archive',
                meta: {
                  documentTitle: ['library_archive_educations', 2]
                },
                component: () => import('@/views/content/libraries/education/Archive')
              }
            ]
          },
          {
            path: 'information',
            name: 'content-libraries-information',
            meta: {
              breadcrumbs: ['additional_information', 2],
              middleware: [
                middleware.hasLibrary(libraries.ADDITIONAL_INFO),
                middleware.hasPermission(permissions.ACCESS_LIBRARIES)
              ]
            },
            component: () => import('@/components/VRouterView'),
            redirect: { name: 'content-libraries-information-index' },
            children: [
              {
                path: '',
                name: 'content-libraries-information-index',
                meta: {
                  documentTitle: ['libraries_informations', 2]
                },
                component: () => import('@/views/content/libraries/additionalInfo/Index')
              },
              {
                path: 'groups',
                name: 'content-libraries-information-groups',
                meta: {
                  breadcrumbs: ['groups', 2],
                  documentTitle: ['library_group_informations', 2]
                },
                component: () => import('@/views/content/libraries/additionalInfo/Groups')
              },
              {
                path: 'archive',
                name: 'content-libraries-information-archive',
                meta: {
                  documentTitle: ['library_archive_informations', 2]
                },
                component: () => import('@/views/content/libraries/additionalInfo/Archive')
              }
            ]
          },
          {
            path: 'experience',
            name: 'content-libraries-experiences',
            meta: {
              middleware: [
                middleware.hasLibrary(libraries.EXPERIENCES),
                middleware.hasPermission(permissions.ACCESS_LIBRARIES)
              ]
            },
            component: () => import('@/components/VRouterView'),
            redirect: { name: 'content-libraries-experiences-index' },
            children: [
              {
                path: '',
                name: 'content-libraries-experiences-index',
                meta: {
                  documentTitle: ['libraries_experiences', 2]
                },
                component: () => import('@/views/content/libraries/experience/Index')
              },
              {
                path: 'archive',
                name: 'content-libraries-experiences-archive',
                meta: {
                  documentTitle: ['library_archive_experiences', 2]
                },
                component: () => import('@/views/content/libraries/experience/Archive')
              }
            ]
          },
          {
            path: 'working-conditions',
            name: 'content-libraries-working-conditions',
            meta: {
              middleware: [
                middleware.hasLibrary(libraries.WORKING_CONDITIONS),
                middleware.hasPermission(permissions.ACCESS_LIBRARIES)
              ]
            },
            component: () => import('@/components/VRouterView'),
            redirect: { name: 'content-libraries-working-conditions-index' },
            children: [
              {
                path: '',
                name: 'content-libraries-working-conditions-index',
                meta: {
                  documentTitle: ['libraries_working_conditions', 2]
                },
                component: () => import('@/views/content/libraries/workingConditions/Index')
              },
              {
                path: 'archive',
                name: 'content-libraries-working-conditions-archive',
                meta: {
                  documentTitle: ['library_archive_working_conditions', 2]
                },
                component: () => import('@/views/content/libraries/workingConditions/Archive')
              }
            ]
          },
          {
            path: 'languages',
            name: 'content-libraries-languages',
            meta: {
              middleware: [
                middleware.hasLibrary(libraries.LANGUAGES),
                middleware.hasPermission(permissions.ACCESS_LIBRARIES)
              ]
            },
            component: () => import('@/components/VRouterView'),
            redirect: { name: 'content-libraries-languages-index' },
            children: [
              {
                path: '',
                name: 'content-libraries-languages-index',
                meta: {
                  documentTitle: ['libraries_languages', 2]
                },
                component: () => import('@/views/content/libraries/languages/Index')
              },
              {
                path: 'archive',
                name: 'content-libraries-languages-archive',
                meta: {
                  documentTitle: ['library_archive_languages', 2]
                },
                component: () => import('@/views/content/libraries/languages/Archive')
              }
            ]
          },
          {
            path: 'certifications',
            name: 'content-libraries-certification',
            meta: {
              breadcrumbs: ['certifications', 2],
              middleware: [
                middleware.hasLibrary(libraries.CERTIFICATIONS),
                middleware.hasPermission(permissions.ACCESS_LIBRARIES)
              ]
            },
            component: () => import('@/components/VRouterView'),
            redirect: { name: 'content-libraries-certification-index' },
            children: [
              {
                path: '',
                name: 'content-libraries-certification-index',
                meta: {
                  documentTitle: ['libraries_certifications', 2]
                },
                component: () => import('@/views/content/libraries/certifications/Index')
              },
              {
                path: 'groups',
                name: 'content-libraries-certification-groups',
                meta: {
                  breadcrumbs: ['groups', 2],
                  documentTitle: ['library_group_certifications', 2]
                },
                component: () => import('@/views/content/libraries/certifications/Groups')
              },
              {
                path: 'archive',
                name: 'content-libraries-certification-archive',
                meta: {
                  documentTitle: ['library_archive_certifications', 2]
                },
                component: () => import('@/views/content/libraries/certifications/Archive')
              }
            ]
          }
        ]
      },
      {
        path: 'interviews',
        name: 'content-interviews',
        meta: {
          breadcrumbs: ['interviews', 2],
          middleware: [
            middleware.hasModule(modules.INTERVIEWS)
          ]
        },
        component: () => import('@/views/content/interviews/Index'),
        children: [
          {
            path: 'openings',
            name: 'content-interviews-openings',
            meta: {
              breadcrumbs: ['openings', 2]
            },
            component: () => import('@/components/VRouterView'),
            redirect: { name: 'content-interviews-openings-index' },
            children: [
              {
                path: '',
                name: 'content-interviews-openings-index',
                meta: {
                  documentTitle: ['openings', 2],
                  middleware: [
                    middleware.hasPermission(permissions.MANAGE_INTERVIEWS, permissions.CONTENT_ADMINISTRATOR)
                  ]
                },
                component: () => import('@/views/content/interviews/openings/Index')
              },
              {
                path: ':id',
                name: 'content-interviews-openings-id',
                props: true,
                component: () => import('@/views/content/interviews/openings/Opening'),
                children: [
                  {
                    path: 'rounds/:roundId',
                    name: 'content-interviews-openings-id-rounds',
                    props: true,
                    component: () => import('@/views/content/interviews/openings/Round'),
                    redirect: { name: 'content-interviews-openings-id-rounds-id' },
                    children: [
                      {
                        path: '',
                        name: 'content-interviews-openings-id-rounds-id',
                        component: () => import('@/views/content/interviews/openings/RoundDetails')
                      },
                      {
                        path: 'compare',
                        name: 'content-interviews-openings-id-rounds-id-compare',
                        meta: {
                          breadcrumbs: ['compare', 2]
                        },
                        component: () => import('@/views/content/interviews/openings/Compare')
                      },
                      {
                        path: 'interview-questions/:guideId',
                        name: 'content-interviews-openings-id-rounds-id-interview-questions',
                        component: () => import('@/views/content/interviews/openings/RoundInterviewQuestions')
                      },
                      {
                        path: 'interviews/:interviewId',
                        name: 'content-interviews-openings-id-rounds-id-interviews-id',
                        component: () => import('@/views/content/interviews/scorecard/Index'),
                        redirect: { name: 'content-interviews-openings-id-rounds-id-interviews-id-scorecard' },
                        children: [
                          {
                            path: 'scorecard',
                            name: 'content-interviews-openings-id-rounds-id-interviews-id-scorecard',
                            meta: {
                              breadcrumbs: ['all_questions']
                            },
                            component: () => import('@/views/content/interviews/scorecard/Scorecard')
                          },
                          {
                            path: 'all-questions',
                            name: 'content-interviews-openings-id-rounds-id-interviews-id-all-questions',
                            meta: {
                              breadcrumbs: ['all_questions']
                            },
                            component: () => import('@/views/content/interviews/scorecard/AllQuestions')
                          },
                          {
                            path: 'question/:questionId',
                            name: 'content-interviews-openings-id-rounds-id-interviews-id-questions-id',
                            meta: {
                              breadcrumbs: ['all_questions']
                            },
                            props: true,
                            component: () => import('@/views/content/interviews/scorecard/Question')
                          }
                        ]
                      },
                      {
                        path: 'candidates/:candidateId',
                        name: 'content-interviews-openings-id-candidates',
                        component: () => import('@/views/content/interviews/openings/Candidate')
                      }
                    ]
                  }
                ]
              },
              {
                path: 'candidates',
                name: 'content-interviews-openings-candidates',
                meta: {
                  documentTitle: ['opening_candidates', 2]
                },
                component: () => import('@/views/content/interviews/openings/Candidates')
              }
            ]
          },
          {
            path: 'templates',
            name: 'content-interviews-guides',
            meta: {
              breadcrumbs: ['interview_templates', 2],
              middleware: [
                middleware.hasPermission(permissions.ACCESS_INTERVIEW_GUIDE_TEMPLATES, permissions.MANAGE_INTERVIEWS, permissions.CONTENT_ADMINISTRATOR)
              ]
            },
            component: () => import('@/components/VRouterView'),
            redirect: { name: 'content-interviews-guides-index' },
            children: [
              {
                path: '',
                name: 'content-interviews-guides-index',
                meta: {
                  documentTitle: ['interview_templates', 2]
                },
                component: () => import('@/views/content/interviews/guides/Index')
              },
              {
                path: ':id',
                name: 'content-interviews-guides-id',
                props: true,
                component: () => import('@/views/content/interviews/guides/Guide')
              },
              {
                path: 'groups',
                name: 'content-interviews-guides-groups',
                meta: {
                  breadcrumbs: ['groups', 2],
                  documentTitle: ['interview_template_groups', 2]
                },
                component: () => import('@/views/content/interviews/guides/Groups')
              },
              {
                path: 'communications',
                name: 'content-interviews-guides-communications',
                meta: {
                  documentTitle: ['interview_template_communications', 2],
                  breadcrumbs: ['communications', 2]
                },
                component: () => import('@/components/VRouterView'),
                redirect: { name: 'content-interviews-guides-communications-index' },
                children: [
                  {
                    path: '',
                    name: 'content-interviews-guides-communications-index',
                    component: () => import('@/views/content/interviews/guides/Communications')
                  },
                  {
                    path: ':id',
                    name: 'content-interviews-guides-communications-id',
                    props: true,
                    component: () => import('@/views/content/interviews/guides/Communication')
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: 'surveys',
        name: 'content-surveys',
        meta: {
          documentTitle: ['surveys', 2],
          breadcrumbs: ['surveys', 2],
          middleware: [
            middleware.hasPermission(permissions.MANAGE_SURVEYS),
            middleware.hasModule(modules.SURVEYS)
          ]
        },
        component: () => import('@/components/VRouterView'),
        redirect: { name: 'content-surveys-index' },
        children: [
          {
            path: '',
            name: 'content-surveys-index',
            meta: {
              parent: 'content-surveys'
            },
            component: () => import('@/views/content/surveys/Index')
          },
          {
            path: ':id',
            name: 'content-surveys-id',
            redirect: { name: 'content-surveys-id-description' },
            meta: {
              parent: 'content-surveys'
            },
            props: true,
            component: () => import('@/views/content/surveys/Survey'),
            children: [
              {
                path: '',
                name: 'content-surveys-id-description',
                component: () => import('@/views/content/surveys/Details')
              },
              {
                path: 'content',
                name: 'content-surveys-id-content',
                meta: {
                  breadcrumbs: ['contents']
                },
                component: () => import('@/views/content/surveys/Content')
              },
              {
                path: 'participants',
                name: 'content-surveys-id-participants',
                meta: {
                  breadcrumbs: ['participants', 2]
                },
                component: () => import('@/views/content/surveys/Participants')
              },
              {
                path: 'communications',
                name: 'content-surveys-id-communications',
                meta: {
                  breadcrumbs: ['communications', 2]
                },
                component: () => import('@/views/content/surveys/Communications')
              },
              {
                path: 'results',
                name: 'content-surveys-id-results',
                meta: {
                  breadcrumbs: ['results', 2]
                },
                component: () => import('@/views/content/surveys/Results')
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/user-management',
    name: 'user-management',
    meta: {
      middleware: [
        middleware.auth,
        middleware.hasPermission(permissions.USERS_ADMINISTRATOR)
      ]
    },
    redirect: { name: 'user-management-users' },
    component: () => import('@/components/VRouterView'),
    children: [
      {
        path: 'import-users',
        name: 'user-management-import-users',
        component: () => import('@/views/userManagement/users/ImportUsers')
      },
      {
        path: 'users',
        name: 'user-management-users',
        meta: {
          documentTitle: ['users', 2],
          breadcrumbs: ['users', 2]
        },
        component: () => import('@/components/VRouterView'),
        redirect: { name: 'user-management-users-index' },
        children: [
          {
            path: '',
            name: 'user-management-users-index',
            component: () => import('@/views/userManagement/users/Users')
          },
          {
            path: ':id',
            name: 'user-management-users-id',
            props: true,
            redirect: { name: 'user-management-users-id-details' },
            component: () => import('@/views/userManagement/users/User'),
            children: [
              {
                path: 'details',
                name: 'user-management-users-id-details',
                component: () => import('@/views/userManagement/users/Details')
              },
              {
                path: 'history',
                name: 'user-management-users-id-history',
                component: () => import('@/views/userManagement/users/History')
              }
            ]
          },
          {
            path: 'deleted-users',
            name: 'user-management-deleted-users',
            meta: {
              documentTitle: ['anonymize_users', 2],
              breadcrumbs: ['deleted_users', 2]
            },
            component: () => import('@/views/userManagement/AnonymizeUsers')
          },
          {
            path: 'permission-sets',
            name: 'user-management-users-permission-sets',
            meta: {
              documentTitle: ['user_permission_sets', 2],
              breadcrumbs: ['permission_sets', 2],
              middleware: [middleware.hasPermission(permissions.PERMISSIONS_ADMINISTRATOR)]
            },
            component: () => import('@/components/VRouterView'),
            redirect: { name: 'user-management-users-permission-sets-index' },
            children: [
              {
                path: '',
                name: 'user-management-users-permission-sets-index',
                meta: {
                  documentTitle: ['user_permission_sets', 2],
                  middleware: [middleware.hasPermission(permissions.PERMISSIONS_ADMINISTRATOR)]
                },
                component: () => import('@/views/userManagement/permissionSets/PermissionSets')
              },
              {
                path: ':id',
                name: 'user-management-users-permission-sets-id',
                meta: {
                  middleware: [middleware.hasPermission(permissions.PERMISSIONS_ADMINISTRATOR)]
                },
                props: true,
                component: () => import('@/views/userManagement/permissionSets/PermissionSet'),
                children: [
                  {
                    path: 'account',
                    name: 'user-management-users-permission-sets-id-account',
                    component: () => import('@/views/userManagement/permissionSets/Account')
                  },
                  {
                    path: 'content',
                    name: 'user-management-users-permission-sets-id-content',
                    component: () => import('@/views/userManagement/permissionSets/Content')
                  },
                  {
                    path: 'users',
                    name: 'user-management-users-permission-sets-id-users',
                    component: () => import('@/views/userManagement/permissionSets/Users')
                  },
                  {
                    path: 'personal',
                    name: 'user-management-users-permission-sets-id-personal',
                    component: () => import('@/views/userManagement/permissionSets/Personal')
                  },
                  {
                    path: 'team',
                    name: 'user-management-users-permission-sets-id-team',
                    component: () => import('@/views/userManagement/permissionSets/Team')
                  },
                  {
                    path: 'organization',
                    name: 'user-management-users-permission-sets-id-organization',
                    component: () => import('@/views/userManagement/permissionSets/Organization')
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: 'positions',
        name: 'user-management-positions',
        meta: {
          documentTitle: ['positions', 2],
          breadcrumbs: ['positions', 2]
        },
        component: () => import('@/components/VRouterView'),
        redirect: { name: 'user-management-positions-index' },
        children: [
          {
            path: '',
            name: 'user-management-positions-index',
            component: () => import('@/views/userManagement/positions/Positions')
          },
          {
            path: ':id',
            name: 'user-management-positions-id',
            props: true,
            component: () => import('@/views/userManagement/positions/Position')
          },
          {
            path: 'departments',
            name: 'user-management-departments',
            meta: {
              documentTitle: ['departments', 2],
              breadcrumbs: ['departments', 2]
            },
            component: () => import('@/views/userManagement/departments/Departments')
          }
        ]
      }
    ]
  },
  {
    path: '/audit-logs',
    name: 'audit-logs',
    meta: {
      breadcrumbs: ['audit_logs', 2],
      permissions: [permissions.ACCESS_AUDIT_LOGS],
      middleware: [middleware.hasPermission(permissions.ACCESS_AUDIT_LOGS)]
    },
    component: () => import('@/components/VRouterView'),
    redirect: { name: 'audit-logs-general' },
    children: [
      {
        path: 'general',
        name: 'audit-logs-general',
        meta: {
          documentTitle: ['audit_logs_general'],
          parent: 'audit-logs'
        },
        component: () => import('@/views/administration/auditLogs/AuditLogsGeneral')
      },
      {
        path: 'jobs',
        name: 'audit-logs-jobs',
        meta: {
          documentTitle: ['audit_logs_jobs', 2],
          parent: 'audit-logs'
        },
        component: () => import('@/views/administration/auditLogs/AuditLogsJobs')
      },
      {
        path: 'signoffs',
        name: 'audit-logs-signoffs',
        meta: {
          documentTitle: ['audit_logs_signoffs', 2],
          parent: 'audit-logs',
          middleware: [
            middleware.hasPermission(permissions.MANAGE_SIGNOFFS),
            middleware.hasFeature(features.COMPLIANCE_SIGNOFFS)
          ]
        },
        component: () => import('@/views/administration/auditLogs/AuditLogsSignoffs')
      },
      {
        path: 'interviews',
        name: 'audit-logs-interviews',
        meta: {
          documentTitle: ['audit_logs_interviews', 2],
          parent: 'audit-logs',
          middleware: [
            middleware.hasModule(modules.INTERVIEWS)
          ]
        },
        component: () => import('@/views/administration/auditLogs/AuditLogsInterviews')
      }
    ]
  },
  {
    path: '/administration',
    name: 'administration',
    meta: {
      middleware: [middleware.auth],
      breadcrumbs: ['admin']
    },
    redirect: { name: 'administration-general-home' },
    component: () => import('@/components/VRouterView'),
    children: [
      {
        path: 'general',
        name: 'administration-general',
        redirect: { name: 'administration-general-home' },
        component: () => import('@/components/VRouterView'),
        children: [
          {
            path: 'home',
            name: 'administration-general-home',
            meta: {
              documentTitle: ['quinto_admin_home']
            },
            component: () => import('@/views/administration/general/Home')
          },
          {
            path: 'account-settings',
            name: 'administration-general-account-settings',
            meta: {
              documentTitle: ['account_settings', 2],
              breadcrumbs: ['account_settings', 2],
              permissions: [permissions.ACCOUNT_ADMINISTRATOR],
              middleware: [middleware.hasPermission(permissions.ACCOUNT_ADMINISTRATOR)]
            },
            component: () => import('@/views/administration/general/AccountSettings')
          },
          {
            path: 'homepage',
            name: 'administration-general-homepage',
            meta: {
              breadcrumbs: ['homepage'],
              documentTitle: ['homepage'],
              permissions: [permissions.ACCOUNT_ADMINISTRATOR],
              middleware: [middleware.hasPermission(permissions.ACCOUNT_ADMINISTRATOR)]
            },
            component: () => import('@/components/VRouterView'),
            redirect: { name: 'administration-general-homepage-index' },
            children: [
              {
                path: '',
                name: 'administration-general-homepage-index',
                meta: {
                  parent: 'administration-general-homepage'
                },
                props: true,
                component: () => import('@/views/administration/general/Homepage')
              },
              {
                path: 'useful-links/:id',
                name: 'administration-general-homepage-useful-links',
                meta: {
                  documentTitle: ['homepage_useful_links', 2],
                  breadcrumbs: ['useful_links', 2],
                  parent: 'administration-general-homepage'
                },
                props: true,
                component: () => import('@/views/administration/general/UsefulLinks')
              },
              {
                path: 'core-competencies/:id',
                name: 'administration-general-homepage-core-competencies',
                meta: {
                  breadcrumbs: ['core_competencies', 2],
                  documentTitle: ['homepage_core_competencies', 2],
                  middleware: [
                    middleware.hasLibrary(libraries.COMPETENCIES)
                  ],
                  parent: 'administration-general-homepage'
                },
                props: true,
                component: () => import('@/views/administration/general/CoreCompetencies')
              },
              {
                path: 'analytics/:id',
                name: 'administration-general-homepage-analytics',
                meta: {
                  documentTitle: ['homepage_job_analytics', 2],
                  breadcrumbs: ['job_analytics', 2],
                  middleware: [
                    middleware.hasPermission(permissions.ACCESS_ANALYTICS)
                  ],
                  parent: 'administration-general-homepage'
                },
                props: true,
                component: () => import('@/views/administration/general/Analytics')
              },
              {
                path: 'welcome-message/:id',
                name: 'administration-general-homepage-welcome-message',
                meta: {
                  breadcrumbs: ['welcome_messages'],
                  documentTitle: ['homepage_welcome_message'],
                  parent: 'administration-general-homepage'
                },
                props: true,
                component: () => import('@/views/administration/general/WelcomeMessage')
              }
            ]
          },
          {
            path: 'communications',
            name: 'administration-general-communications',
            meta: {
              breadcrumbs: ['communications', 2],
              documentTitle: ['communications', 2],
              permissions: [permissions.COMMUNICATIONS_ADMINISTRATOR],
              middleware: [middleware.hasPermission(permissions.COMMUNICATIONS_ADMINISTRATOR)]
            },
            component: () => import('@/components/VRouterView'),
            redirect: { name: 'administration-general-communications-index' },
            children: [
              {
                path: '',
                name: 'administration-general-communications-index',
                meta: {
                  parent: 'administration-general-communications'
                },
                component: () => import('@/views/administration/general/communication/Index')
              },
              {
                path: ':id',
                name: 'administration-general-communications-id',
                props: true,
                meta: {
                  parent: 'administration-general-communications'
                },
                component: () => import('@/views/administration/general/communication/CommunicationType')
              }
            ]
          },
          {
            path: 'locations',
            name: 'administration-general-locations',
            meta: {
              documentTitle: ['locations', 2],
              breadcrumbs: ['locations', 2],
              permissions: [permissions.MANAGE_LOCATIONS],
              middleware: [middleware.hasPermission(permissions.MANAGE_LOCATIONS)]
            },
            component: () => import('@/views/administration/general/Locations')
          },
          {
            path: 'user-defaults',
            name: 'administration-general-user-defaults',
            meta: {
              documentTitle: ['user_defaults', 2],
              breadcrumbs: ['user_defaults', 2],
              permissions: [permissions.ACCOUNT_ADMINISTRATOR],
              middleware: [middleware.hasPermission(permissions.ACCOUNT_ADMINISTRATOR)]
            },
            component: () => import('@/views/administration/general/UserDefaults')
          },
          {
            path: 'integrations',
            name: 'administration-general-integrations',
            meta: {
              breadcrumbs: ['integrations', 2],
              permissions: [permissions.ACCOUNT_ADMINISTRATOR],
              middleware: [middleware.hasPermission(permissions.ACCOUNT_ADMINISTRATOR)]
            },
            redirect: { name: 'administration-general-integrations-hris' },
            component: () => import('@/views/administration/general/integration/Index'),
            children: [
              {
                path: 'hris',
                name: 'administration-general-integrations-hris',
                meta: {
                  documentTitle: ['integrations', 2],
                  parent: 'administration-general-integrations'
                },
                component: () => import('@/views/administration/general/integration/IntegrationHris')
              },
              {
                path: 'ats',
                name: 'administration-general-integrations-ats',
                meta: {
                  documentTitle: ['integrations', 2],
                  parent: 'administration-general-integrations'
                },
                component: () => import('@/views/administration/general/integration/IntegrationAts')
              }
            ]
          },
          {
            path: 'api-keys',
            name: 'administration-general-api-keys',
            meta: {
              breadcrumbs: ['api_keys', 2],
              documentTitle: ['api_keys', 2],
              middleware: [middleware.hasPermission(permissions.ACCOUNT_ADMINISTRATOR)]
            },
            component: () => import('@/views/administration/general/apiKeys/Index')
          }
        ]
      },
      {
        path: 'content',
        name: 'administration-content',
        meta: {
          middleware: [middleware.hasPermission(permissions.CONTENT_ADMINISTRATOR)]
        },
        redirect: { name: 'administration-content-architecture' },
        component: () => import('@/components/VRouterView'),
        children: [
          {
            path: 'architecture',
            name: 'administration-content-architecture',
            meta: {
              documentTitle: ['architecture'],
              breadcrumbs: ['architecture'],
              middleware: [middleware.hasLibrary(libraries.COMPETENCIES)]
            },
            component: () => import('@/views/administration/content/Architecture')
          },
          {
            path: 'interviews',
            name: 'administration-content-interviews',
            meta: {
              documentTitle: ['interviews', 2],
              breadcrumbs: ['interviews', 2],
              middleware: [middleware.hasPermission(permissions.CONTENT_ADMINISTRATOR)]
            },
            component: () => import('@/views/administration/content/interviews/Index'),
            redirect: { name: 'administration-content-interviews-interview-rounds' },
            children: [
              {
                path: 'interview-rounds',
                name: 'administration-content-interviews-interview-rounds',
                meta: {
                  parent: 'administration-content-interviews'
                },
                component: () => import('@/views/administration/content/interviews/InterviewRounds')
              },
              {
                path: 'candidate-statuses',
                name: 'administration-content-interviews-candidate-statuses',
                meta: {
                  parent: 'administration-content-interviews'
                },
                component: () => import('@/views/administration/content/interviews/CandidateStatuses')
              }
            ]
          },
          {
            path: 'AI',
            name: 'administration-content-ai',
            meta: {
              documentTitle: ['ai'],
              breadcrumbs: ['ai']
            },
            component: () => import('@/views/administration/content/AI')
          },
          {
            path: 'libraries',
            name: 'administration-content-libraries',
            meta: {
              documentTitle: ['libraries', 2],
              breadcrumbs: ['libraries', 2]
            },
            component: () => import('@/views/administration/content/Libraries')
          },
          {
            path: 'job-properties',
            name: 'administration-content-job-properties',
            meta: {
              breadcrumbs: ['job_properties', 2],
              documentTitle: ['job_properties', 2]
            },
            component: () => import('@/components/VRouterView'),
            redirect: { name: 'administration-content-job-properties-index' },
            children: [
              {
                path: '',
                name: 'administration-content-job-properties-index',
                component: () => import('@/views/administration/content/jobProperties/Index'),
                meta: {
                  parent: 'administration-content-job-properties'
                }
              },
              {
                path: ':id',
                name: 'administration-content-job-properties-id',
                props: true,
                component: () => import('@/views/administration/content/jobProperties/JobProperty'),
                meta: {
                  parent: 'administration-content-job-properties'
                }
              }
            ]
          },
          {
            path: 'job-templates',
            name: 'administration-content-job-templates',
            meta: {
              documentTitle: ['job_templates', 2],
              breadcrumbs: ['job_templates', 2]
            },
            component: () => import('@/components/VRouterView'),
            redirect: { name: 'administration-content-job-templates-index' },
            children: [
              {
                path: '',
                name: 'administration-content-job-templates-index',
                component: () => import('@/views/administration/content/JobTemplateTable'),
                meta: {
                  parent: 'administration-content-job-templates'
                }
              },
              {
                path: ':id',
                name: 'administration-content-job-templates-id',
                props: true,
                component: () => import('@/views/administration/content/JobTemplate'),
                meta: {
                  parent: 'administration-content-job-templates'
                }
              },
              {
                path: 'post/:id',
                name: 'administration-content-job-templates-post-id',
                props: true,
                component: () => import('@/views/administration/content/JobPostTemplate'),
                meta: {
                  parent: 'administration-content-job-templates'
                }
              }
            ]
          },
          {
            path: 'scales',
            name: 'administration-content-scales',
            meta: {
              documentTitle: ['scales', 2],
              breadcrumbs: ['scales', 2]
            },
            component: () => import('@/components/VRouterView'),
            redirect: { name: 'administration-content-scales-index' },
            children: [
              {
                path: '',
                name: 'administration-content-scales-index',
                component: () => import('@/views/administration/content/scales/Index'),
                meta: {
                  parent: 'administration-content-scales'
                }
              },
              {
                path: ':id',
                name: 'administration-content-scales-id',
                props: true,
                component: () => import('@/views/administration/content/scales/ScaleType'),
                meta: {
                  parent: 'administration-content-scales'
                }
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/content-warehouse',
    name: 'content-warehouse',
    meta: {
      middleware: [middleware.auth]
    },
    component: () => import('@/components/VRouterView'),
    redirect: { name: 'content-warehouse-jobs' },
    children: [
      {
        path: 'jobs',
        name: 'content-warehouse-jobs',
        meta: {
          breadcrumbs: ['jobs', 2],
          middleware: [
            middleware.hasPermission(permissions.MANAGE_JOBS),
            middleware.hasLibraryContent(libraries.JOBS)
          ]
        },
        component: () => import('@/components/VRouterView'),
        redirect: { name: 'content-warehouse-jobs-index' },
        children: [
          {
            path: '',
            name: 'content-warehouse-jobs-index',
            meta: {
              documentTitle: ['warehouse_jobs', 2]
            },
            component: () => import('@/views/warehouse/Jobs')
          },
          {
            path: ':handle',
            name: 'content-warehouse-jobs-id',
            props: true,
            component: () => import('@/views/warehouse/Job')
          }
        ]
      },
      {
        path: 'libraries',
        name: 'content-warehouse-libraries',
        meta: {
          breadcrumbs: ['libraries', 2],
          middleware: [
            middleware.hasPermission(permissions.MANAGE_LIBRARIES, permissions.MANAGE_INTERVIEWS, permissions.CONTENT_ADMINISTRATOR)
          ]
        },
        component: () => import('@/components/VRouterView'),
        redirect: { name: 'content-warehouse-libraries-competencies' },
        children: [
          {
            path: 'competencies',
            name: 'content-warehouse-libraries-competencies',
            meta: {
              breadcrumbs: ['competencies', 2]
            },
            component: () => import('@/components/VRouterView'),
            redirect: { name: 'content-warehouse-libraries-competencies-index' },
            children: [
              {
                path: '',
                name: 'content-warehouse-libraries-competencies-index',
                meta: {
                  documentTitle: ['warehouse_libraries_competencies', 2]
                },
                component: () => import('@/views/warehouse/libraries/Competencies')
              },
              {
                path: ':handle',
                name: 'content-warehouse-libraries-competencies-id',
                props: true,
                meta: {
                  middleware: [
                    middleware.hasLibraryContent(libraries.COMPETENCIES)
                  ]
                },
                component: () => import('@/views/warehouse/libraries/Competency')
              }
            ]
          },
          {
            path: 'responsibilities',
            name: 'content-warehouse-libraries-responsibilities',
            meta: {
              documentTitle: ['warehouse_libraries_responsibilities', 2],
              middleware: [
                middleware.hasLibraryContent(libraries.RESPONSIBILITIES)
              ]
            },
            component: () => import('@/views/warehouse/libraries/Responsibilities')
          },
          {
            path: 'skills',
            name: 'content-warehouse-libraries-skills',
            meta: {
              documentTitle: ['warehouse_libraries_skills', 2],
              middleware: [
                middleware.hasLibraryContent(libraries.SKILLS)
              ]
            },
            component: () => import('@/views/warehouse/libraries/Skills')
          },
          {
            path: 'certifications',
            name: 'content-warehouse-libraries-certifications',
            meta: {
              documentTitle: ['warehouse_libraries_certifications', 2],
              middleware: [
                middleware.hasLibraryContent(libraries.CERTIFICATIONS)
              ]
            },
            component: () => import('@/views/warehouse/libraries/Certifications')
          },
          {
            path: 'education',
            name: 'content-warehouse-libraries-education',
            meta: {
              documentTitle: ['warehouse_libraries_educations', 2],
              middleware: [
                middleware.hasLibraryContent(libraries.EDUCATIONS)
              ]
            },
            component: () => import('@/views/warehouse/libraries/Education')
          },
          {
            path: 'interview-questions',
            name: 'content-warehouse-libraries-questions',
            meta: {
              documentTitle: ['warehouse_interview_questions', 2],
              middleware: [
                middleware.hasPermission(permissions.MANAGE_INTERVIEWS, permissions.CONTENT_ADMINISTRATOR),
                middleware.hasLibraryContent(libraries.INTERVIEW_QUESTIONS)
              ]
            },
            component: () => import('@/views/warehouse/InterviewQuestions')
          }
        ]
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'error',
    meta: {
      middleware: [middleware.error]
    },
    component: () => import('@/views/Error')
  }
];
