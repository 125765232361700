<template>
  <div
    class="py-5 cursor-default group"
    :class="isDone ? 'bg-white text-grey-2' : 'text-magenta-0'"
  >
    <div class="flex justify-between items-center gap-2">
      <VIcon
        v-if="task.icon"
        :icon="task?.icon"
        :color="isDone ? 'text-grey-2' : 'text-magenta-0'"
        size="text-3xl"
        class="mr-2 self-baseline"
        :class="iconClass"
      />
      <div class="w-4/5 grid gap-1">
        <div class="flex items-start font-semibold">
          <VTruncateText
            :text="task?.title"
            :length="titleTruncateLength"
            :delay="500"
            always-show-tooltip
            :class="[titleClass, { 'group-hover:underline group-hover:cursor-pointer': enabled }]"
            @click="onClickTitle"
          />

          <VIcon
            icon="star"
            :color="isStarred ? 'text-yellow-1' : 'text-grey-2'"
            class="ml-2 invisible group-hover:visible cursor-pointer text-xl leading-none relative top-px"
            :class="{ '!visible icon-filled': isStarred }"
            @click="onToggleStar"
          />
        </div>

        <div class="flex gap-3 text-xs text-grey-1">
          <div class="truncate">
            <slot>
              {{ $t('from_name', { name }) }}
            </slot>
          </div>

          <div
            v-if="date"
            class="flex items-center justify-end gap-1 whitespace-nowrap"
          >
            <VIcon
              icon="event"
              color="text-grey-1"
              size="text-[15px]"
              weight="font-light"
              class="mb-px"
            />
            <slot name="date">
              {{ format(date) }}
            </slot>
          </div>
        </div>
      </div>

      <div class="w-1/5 flex justify-end items-center">
        <div
          v-if="task.status === STATUSES.CANCELLED"
          class="px-2 py-2 text-grey-1 text-xs font-normal"
        >
          {{ $t('cancelled') }}
        </div>

        <div v-if="task.status === STATUSES.COMPLETED">
          <slot name="completed">
            <div class="px-2 py-2 text-grey-1 text-xs font-normal">
              {{ $t('done') }}
            </div>
          </slot>
        </div>

        <div v-if="task.status === STATUSES.OPEN">
          <slot name="open" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
// Composables
import useDate from '@/composables/useDate';
// Components
import VIcon from '@/components/VIcon';
import VTruncateText from '@/components/VTruncateText';
// Utils
import { helpers } from '@/utils/helpers';

export default {
  components: {
    VIcon,
    VTruncateText
  },
  props: {
    task: {
      type: Object,
      default: () => ({})
    },
    enabled: {
      type: Boolean,
      default: false
    },
    titleClass: {
      type: [String, Object],
      default: ''
    },
    titleTruncateLength: {
      type: Number,
      default: 60
    },
    iconClass: {
      type: [String, Object],
      default: ''
    }
  },
  emits: ['click', 'star'],
  setup (props, context) {
    // Computed
    const { format } = useDate();

    // Constants
    const STATUSES = {
      COMPLETED: 'Completed',
      CANCELLED: 'Cancelled',
      OPEN: 'Open'
    };

    // Computed
    const isDone = computed(() => [STATUSES.COMPLETED, STATUSES.CANCELLED].includes(props.task?.status));
    const name = computed(() => helpers.getFullName(props.task.assigned_by));
    const isStarred = computed(() => helpers.isYes(props.task.starred));

    const date = computed(() => {
      const statuses = {
        [STATUSES.COMPLETED]: props.task?.completed_at,
        [STATUSES.CANCELLED]: props.task?.cancelled_at,
        [STATUSES.OPEN]: props.task?.due_date
      };

      return statuses[props.task?.status] ?? '';
    });

    // Methods
    const onClickTitle = () => {
      if (!props.enabled) {
        return;
      }

      context.emit('click');
    };

    const onToggleStar = () => context.emit('star', props.task.id);

    return {
      STATUSES,
      name,
      date,
      isDone,
      isStarred,
      onClickTitle,
      onToggleStar,
      // useDate
      format
    };
  }
};
</script>
