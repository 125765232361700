<template>
  <VTask
    :task="task"
    v-bind="$attrs"
    enabled
    icon-class="!-mt-0.5"
    @click="onClick"
  >
    <template #default>
      <span>
        {{ $t('from_name', { name: $h.getFullName(task?.assigned_by) }) }}
      </span>
    </template>

    <template #open>
      <VButton
        class="btn secondary !text-magenta-0 btn-sm !border-magenta-1 hover:!bg-task-btn-bg round"
        :class="btnSize"
        @click="onClick"
      >
        {{ $t('open') }}
      </VButton>
    </template>
  </VTask>
</template>

<script>
import { useRouter } from 'vue-router';
// Components
import VTask from '@/components/templates/tasks/VTask';
import VButton from '@/components/VButton';

export default {
  components: {
    VTask,
    VButton
  },
  props: {
    task: {
      type: Object,
      default: () => ({})
    },
    btnSize: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    // Misc
    const router = useRouter();

    // Methods
    const onClick = () => {
      const { review_id: reviewId, assessment_id: assessmentId } = props?.task?.details ? JSON.parse(props?.task?.details) : {};

      if (reviewId && assessmentId) {
        router.push({
          name: 'content-reviews-id-assessments-id-participant-id-questionnaire-index',
          params: {
            id: reviewId,
            assessmentId,
            participantId: props.task?.taskable_id
          }
        });
      }
    };

    return {
      onClick
    };
  }
};
</script>
